<template>
  <div class="pb-32">
    <HeroComp>
      <div class="mt-4">
        <UserDetailsComp />
      </div>
    </HeroComp>

    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="img in images" :key="img" class="h-48 bg-red-300 relative">
        <van-image class="object-center h-full w-full" :src="img" fit="cover"></van-image>
      </van-swipe-item>
    </van-swipe>

    <van-search class="mb-2" v-model="filterFeature" round placeholder="Search for..." shape="round" />

    <FeatureItemsComp :search-filter="filterFeature" />

    <FloatingNavbarMenu />
  </div>
</template>

<script>
  import FeatureItemsComp from "@/components/FeatureItemsComp.vue";
  import HeroComp from "@/components/HeroComp.vue";
  import FloatingNavbarMenu from "@/components/FloatingNavbarMenu.vue";
  import UserDetailsComp from "@/components/UserDetailsComp.vue";
  // import FromDeveloper from "@/components/FromDeveloper.vue";
  // import { Carousel, Slide } from "vue-carousel";

  export default {
    name: "DashboardPage",
    data() {
      return {
        filterFeature: "",
        images: ["https://r2-cdn-smoc.gssp.co.id/banner-1.jpg"]
      };
    },
    components: {
      FeatureItemsComp,
      HeroComp,
      FloatingNavbarMenu,
      UserDetailsComp
      // FromDeveloper
      // Carousel,
      // Slide
    }
  };
</script>
