<template>
  <div class="sticky top-0 h-0 z-10">
    <div
      class="px-4 py-2 duration-500 z-10 flex absolute top-0 w-full"
      :class="isFarEnough || stuck ? 'text-primary bg-white shadow-md' : 'text-white'"
    >
      <h1 class="w-full">
        <span class="text-2xl font-bold"> TIME </span>
        <span class="text-lg mr-2" v-if="title">/ {{ title }}</span>
        <span class="opacity-50">v 1.0.4.rc</span>
      </h1>
      <div>
        <v-icon icon="material-symbols:signal-disconnected" class="text-3xl hidden" />
        <v-icon v-if="online" icon="material-symbols:notifications" class="text-3xl" />
        <v-icon v-else icon="material-symbols:signal-disconnected" class="text-3xl" />
      </div>
    </div>
  </div>
</template>

<script>
  import { useWindowScroll } from "@vueuse/core";
  import { online } from "@/states/local";
  export default {
    name: "NavigationBarComp",
    data() {
      return {
        online,
        scroll: useWindowScroll()
      };
    },
    props: {
      title: {
        type: String,
        default: ""
      },
      stuck: {
        type: Boolean,
        default: false
      }
    },
    components: {},
    computed: {
      isFarEnough() {
        return this.scroll.y > 24;
      }
    }
  };
</script>
