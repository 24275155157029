import instance from "@/plugins/axios";
import { reactive } from "vue";

export const users = reactive({
  data: {},
  loading: false,
  error: null
});

export const fetchUsers = async () => {
  return new Promise((resolve, reject) => {
    users.loading = true;
    instance
      .get("/user/@me")
      .then((res) => {
        users.data = res.data;
        users.error = null;
        users.loading = false;
        resolve(res.data);
      })
      .catch((err) => {
        users.error = err;
        users.loading = false;
        reject(err);
      });
  });
};
