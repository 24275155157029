<template>
  <div class="w-full absolute flex justify-center items-center h-full px-4">
    <div class="max-w-sm">
      <div class="text-center">
        <van-image src="/images/time_transparent.png" width="8rem" height="8rem" />
      </div>
      <van-form @submit="login">
        <van-field
          v-model="loginForm.username"
          placeholder="Username"
          :rules="[{ required: true, message: 'Username is required' }]"
        />
        <van-field
          v-model="loginForm.password"
          type="password"
          name="Password"
          placeholder="Password"
          :rules="[{ required: true, message: 'Password is required' }]"
        />
        <div class="mt-4 font-bold">
          <van-button round block type="info" native-type="submit"> Login </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
  import axios from "@/plugins/axios";

  export default {
    name: "HomePage",

    data() {
      return {
        loginForm: {
          username: "",
          password: ""
        },
        peekPassword: false
      };
    },

    methods: {
      login() {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          loadingType: "spinner",
          message: "Loading..."
        });

        axios
          .post("/login", this.loginForm)

          .then((res) => {
            axios.defaults.headers["Authorization"] = res.data.token;
            localStorage.setItem("token", res.data.token);
            this.$toast.clear();
            this.$router.push("/dashboard");
          })
          .catch((err) => {
            console.error(err);
            this.$notify("Invalid username or password");
            this.$toast.clear();
          });
      }
    }
  };
</script>
