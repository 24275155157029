<template>
  <div class="bg-slate-100 min-h-screen">
    <div>
      <NavigationBarComp :title="$attrs?.title" :stuck="true" />
      <div class="pt-12">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import NavigationBarComp from "@/components/NavigationBarComp.vue";

  export default {
    name: "DashboardLayout",
    components: {
      NavigationBarComp
    }
  };
</script>
