<template>
  <div class="bg-slate-100 min-h-screen">
    <div>
      <NavigationBarComp />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import NavigationBarComp from "@/components/NavigationBarComp.vue";
  import { fetchUsers } from "@/states/user";

  export default {
    name: "DashboardLayout",
    components: {
      NavigationBarComp
    },
    async mounted() {
      await fetchUsers();
    }
  };
</script>
