class CameraHandler {
  width = 720;
  height = 720;
  constructor(html, width, height) {
    this.videoElement = html;
    this.width = width;
    this.height = height;
    this.stream = null;
    this.devices = [];
    this.currentDeviceId = null;
  }

  async getDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.devices = devices
        .filter((device) => device.kind === "videoinput")
        .map((device) => ({ id: device.deviceId, name: device.label }));
      return this.devices;
    } catch (error) {
      console.error("Error getting devices: ", error);
      return [];
    }
  }

  async switchCamera(deviceId) {
    this.stop();
    this.currentDeviceId = deviceId;
    await this.start();
  }

  async start() {
    try {
      const constraints = {
        audio: false,
        video: {
          deviceId: this.currentDeviceId ? { exact: this.currentDeviceId } : undefined,
          width: { min: this.width, ideal: this.width },
          height: { min: this.height, ideal: this.height },
          aspectRatio: { exact: this.width / this.height }
        }
      };
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        this.stream = await navigator.mediaDevices.getUserMedia(constraints);

        this.videoElement.srcObject = this.stream;
        // this.videoElement.src = this.stream;
        this.videoElement.play();

        /* Legacy code below! */
        // Standard
      } else if (navigator.getUserMedia) {
        navigator.getUserMedia(
          constraints,
          (stream) => {
            this.stream = stream;
            // this.videoElement.src = stream;
            this.videoElement.srcObject = stream;
            this.videoElement.play();
          },
          (e) => {
            console.error("is 1", e);
          }
        );
      } else if (navigator.webkitGetUserMedia) {
        // WebKit-prefixed
        navigator.webkitGetUserMedia(
          constraints,
          (stream) => {
            this.stream = stream;
            this.videoElement.src = window.webkitURL.createObjectURL(stream);
            this.videoElement.play();
          },
          (e) => {
            console.error("is 2", e);
          }
        );
      } else if (navigator.mozGetUserMedia) {
        // Mozilla-prefixed
        navigator.mozGetUserMedia(
          constraints,
          (stream) => {
            this.stream = stream;
            this.videoElement.src = window.URL.createObjectURL(stream);
            this.videoElement.play();
          },
          (e) => {
            console.error("is 3", e);
          }
        );
      }
    } catch (error) {
      console.error("Error starting camera: ", error);
      return Promise.reject(error);
    }
  }

  stop() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.videoElement.pause();
      this.videoElement.srcObject = null;
      this.stream = null;
    }
  }

  async toggleTorch() {
    if (!this.stream) {
      throw new Error("Camera is not started");
    }

    const tracks = this.stream?.getVideoTracks();
    if (tracks.length === 0) {
      throw new Error("No video tracks found");
    }

    const track = tracks[0];
    const capabilities = track?.getCapabilities();
    if (!capabilities.torch) {
      throw new Error("Torch not supported");
    }

    const mode = track?.getSettings()?.torch ? "off" : "on";
    await track.applyConstraints({ advanced: [{ torch: mode }] });
  }

  capture(fileType = "image/jpeg", canvasCallback = function () {}, width, height) {
    if (!this.stream) {
      throw new Error("Camera is not started");
    }

    const canvas = document.createElement("canvas");
    canvas.width = width || this.videoElement.videoWidth;
    canvas.height = height || this.videoElement.videoHeight;
    const context = canvas.getContext("2d");

    if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
      context.fillStyle = "black";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(this.videoElement, 0, 0, canvas.width, canvas.height);

      canvasCallback(context);
      var now = new Date().toLocaleString();
      context.font = "12px Arial";
      const textWidth = context.measureText(now);
      context.fillStyle = "#0007";
      context.fillRect(4, 6, textWidth.width + 12, 8 + 12);
      context.fillStyle = "white";
      context.fillText(now, 10, 20);

      return new Promise((resolve) => {
        if (canvas.toBlob) {
          canvas.toBlob((blob) => {
            resolve(blob);
          }, fileType);
        } else {
          resolve(canvas.toDataURL(fileType));
        }
      });
    } else {
      return Promise.reject(new Error("Video element is not ready"));
    }
  }
}

export default CameraHandler;
